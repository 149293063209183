import React from "react";
import Shape4 from "../../assets/images/shapes/shape4.svg";
import { StyledLoader } from "./Loader.style";
const Loader = ({text}) => {
  return (
    <StyledLoader>
      <div className="loader">
        {text && <p>{text}</p>}
        <div className="loader__shape">
          <Shape4 />
          <Shape4 />
          <Shape4 />
        </div>
      </div>
    </StyledLoader>
  )
};
export default Loader;