import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import Layout from '~/components/Layout/Layout'
import Metadata from '~/components/Metadata'
import { OrderConfirmation } from '~/components/OrderConfirmation/OrderConfirmation'

const Account = () => {
  const translate = useTranslate()
  return (
    <Layout>
      <Metadata title={translate('confirmation.page_title')} />
      <OrderConfirmation />
    </Layout>
  )
}

export default Account
