import styled from 'styled-components';

export const StyledLoader = styled.div`
  background: rgba(255, 255, 255, 0.5);
  padding: 3rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  p {
    font-family: var(--secondaryFont);
  }
  svg {
    width: 0.75em;
    height: 0.75em;
    margin: 0 0.2em;
    fill: var(--red);
    animation: loaderDotAnimation 1s linear infinite;
    &:nth-child(2) {
      animation-delay: 0.2s;
    }
    &:nth-child(3) {
      animation-delay: 0.4s;
    }
  }

  @keyframes loaderDotAnimation {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(0.5em);
    }
    100% {
      transform: translateY(0);
    }
  }
`;
